export default {
    "7z": "zip",
	"ahk": "ahk",
	"ai": "ai",
	"apk": "apk",
	"asm": "asm",
	"asp": "asp",
	"aspx": "asp",
	"avi": "mp4",
	"bashrc": "sh",
	"bat": "bat",
	"bmp": "bmp",
	"bz2": "zip",
	"c": "c",
	"cc": "cpp",
	"cfg": "cfg",
	"cjs": "js",
	"cmd": "bat",
	"conf": "cfg",
	"cpp": "cpp",
	"crt": "crt",
	"cs": "cs",
	"css": "css",
	"csv": "xls",
	"cxx": "cpp",
	"db": "dll",
	"dds": "dds",
	"dll": "dll",
	"doc": "doc",
	"docx": "docx",
	"dot": "dot",
	"dtd": "dtd",
	"emf": "emf",
	"eml": "eml",
	"env": "cfg",
	"epub": "epub",
	"exe": "exe",
	"gif": "gif",
	"gitignore": "cfg",
	"go": "go",
	"gz": "zip",
	"h": "h",
	"heic": "heic",
	"heif": "heif",
	"hpp": "h",
	"htm": "html",
	"html": "html",
	"ico": "ico",
	"ics": "ics",
	"ini": "cfg",
	"ipynb": "txt",
	"iso": "iso",
	"jar": "jar",
	"java": "java",
	"jpeg": "jpeg",
	"jpg": "jpg",
	"js": "js",
	"json": "json",
	"jsp": "asp",
	"jsx": "jsx",
	"less": "less",
	"lib": "lib",
	"lic": "lic",
	"log": "log",
	"lst": "lic",
	"m4a": "m4a",
	"map": "lic",
	"markdown": "md",
	"md": "md",
	"mk": "mk",
	"mobi": "epub",
	"mp3": "mp3",
	"mp4": "mp4",
	"msi": "msi",
	"obj": "obj",
	"odp": "odp",
	"ods": "ods",
	"odt": "odt",
	"otf": "otf",
	"ova": "ova",
	"ovpn": "ovpn",
	"pdf": "pdf",
	"pem": "pem",
	"php": "php",
	"png": "png",
	"pot": "pot",
	"ppa": "ppa",
	"pps": "ppt",
	"ppt": "ppt",
	"pptx": "pptx",
	"properties": "cfg",
	"ps1": "ps1",
	"psd": "psd",
	"pwz": "pwz",
	"py": "py",
	"pyc": "pyc",
	"rar": "zip",
	"ras": "heic",
	"raw": "heif",
	"rb": "rb",
	"rtf": "doc",
	"sass": "scss",
	"scss": "scss",
	"sh": "sh",
	"sql": "sql",
	"svg": "svg",
	"tar": "zip",
	"tga": "tga",
	"tgz": "zip",
	"tif": "tiff",
	"tiff": "tiff",
	"ts": "ts",
	"tsx": "jsx",
	"ttf": "otf",
	"txt": "txt",
	"vb": "vb",
	"vbs": "vbs",
	"vcf": "vcf",
	"vdx": "vdx",
	"vhdx": "vhdx",
	"vmdk": "vmdk",
	"vmx": "vmx",
	"vsd": "vdx",
	"vue": "vue",
	"webp": "webp",
	"wiz": "wiz",
	"wmf": "wmf",
	"wmv": "wmv",
	"wsdl": "wsdl",
	"xhtml": "html",
	"xls": "xls",
	"xlsx": "xlsx",
	"xml": "xml",
	"xsl": "xsl",
	"xz": "zip",
	"yaml": "yml",
	"yml": "yml",
	"zip": "zip"
};